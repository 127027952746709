<template>
  <div class="">
    <Settings />
    <div class="overflow-y-scroll px-4 md:px-8 xl:px-24 xxl:max-w-screen-xxl xxl:m-auto 2xl:px-0">
      <div class="mt-8 large-layout">
        <div class="md:w-115 lg:w-80">
          <span class="title">Employment status</span>
          <p class="mt-2 text-sm text-sec-text">What is your current employment status?</p>
        </div>
        <div class="mt-4 lg:mt-0 max-w-xl">
          <div class="flex flex-col input-wrap md:mt-0 mt-4">
            <label class="text-xs inline-block text-sec-text title"
              >Employment status</label
            >
            <div
              class="flex items-center w-full h-11 border rounded-lg bg-main-bg"
            >
              <select
                class="bg-transparent w-full h-full text-title outline-none rounded-lg text-sm pl-4 minimal"
                @change="changeEmplymentStatus"
                id="employment-status"
                v-model="proBody.employmentStatus"
              >
                <option selected="selected">Select employment status</option>
                <option value="employed">Employed</option>
                <option value="unemployed">Unemployed</option>
                <option value="self-employed">Self Employed</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8 lg:mt-25 large-layout">
        <div class="lg:w-80">
          <span class="title">Net monthly income</span>
          <div class="text-sec-text">
            <p class="mt-2 text-sm">What is your take home salary every month?</p>
            <p class="flex mt-4 text-info text-xs w-64">
              <span class="mr-2">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 6V10M10 14H10.01M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z" stroke="#2772EE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
              We use this infomation to estimate how much you can access.
            </p>
          </div>
        </div>
        <div class="mt-4 lg:mt-0 max-w-xl">
          <div class="flex flex-col input-wrap md:mt-0 mt-4">
            <label class="text-xs inline-block text-sec-text title"
              >Net monthly income</label
            >
            <div class="flex items-center w-full border rounded-lg bg-main-bg">
              <input
                class="bg-transparent w-full h-full text-title outline-none rounded-lg text-sm px-4 py-3"
                type="text"
                name="income"
                autocomplete="off"
                placeholder="Enter monthly income"
                v-model="proBody.netMonthlyIncome"
                @keyup="income()"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8 lg:mt-25 large-layout">
        <div class="lg:w-80">
          <span class="title">Employer name</span>
          <div class="text-sec-text">
            <p class="mt-2 text-sm">
              Kindly fill in the full name of your employer
            </p>
            <p class="flex mt-4 text-info text-xs w-64">
              <span class="mr-2">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 6V10M10 14H10.01M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z" stroke="#2772EE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
                Working for a company already listed in our database 
                gets your loan approved faster.
            </p>
          </div>
        </div>
        <div class="mt-4 lg:mt-0 max-w-xl">
          <div class="flex flex-col input-wrap md:mt-0 mt-4">
            <label class="text-xs inline-block text-sec-text title"
              >Employer name</label
            >
            <div class="flex items-center w-full border rounded-lg bg-main-bg">
              <input
                class="bg-transparent w-full h-full text-title outline-none rounded-lg text-sm px-4 py-3"
                type="text"
                name="employer-name"
                autocomplete="off"
                placeholder="Enter employer name"
                v-model="proBody.employerName"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8 lg:mt-25 large-layout">
        <div class="lg:w-80">
          <span class="title">Employer address</span>
          <p class="mt-2 text-sm">
            Where is your company’s office located?
          </p>
        </div>
        <div class="mt-4 lg:mt-0 max-w-xl">
          <div class="flex flex-col input-wrap md:mt-0 mt-4">
            <label class="text-xs inline-block text-sec-text title"
              >Employer address</label
            >
            <div class="flex items-center w-full border rounded-lg bg-main-bg">
              <input
                id="employer-address"
                class="bg-transparent w-full h-full text-title outline-none rounded-lg text-sm px-4 py-3"
                type="text"
                name="employer-address"
                autocomplete="off"
                placeholder="Enter employer address"
                :value="proBody.employerAddress"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8 lg:mt-25 large-layout">
        <div class="lg:w-80">
          <span class="title">Official email address</span>
          <div class="text-sec-text">
            <p class="mt-2 text-sm">
              What is your official email address if you have one?
            </p>
            <p class="flex mt-4 text-info text-xs w-64">
              <span class="mr-2">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 6V10M10 14H10.01M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z" stroke="#2772EE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
                Access to an official email address could help us waive some requirements such as work identification card. 
            </p>
          </div>
        </div>
        <div class="mt-4 lg:mt-0 max-w-xl">
          <div class="flex flex-col input-wrap md:mt-0 mt-4">
            <label class="text-xs inline-block text-sec-text title"
              >Official email address</label
            >
            <div class="flex items-center w-full border rounded-lg bg-main-bg">
              <input
                class="bg-transparent w-full h-full text-title outline-none rounded-lg text-sm px-4 py-3"
                type="email"
                name="official-email"
                autocomplete="off"
                placeholder="Enter official email address"
                v-model="proBody.officialEmailAddress"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8 lg:mt-25 large-layout">
        <div class="md:w-115 lg:w-80">
          <span class="title">Duration of employment</span>
          <p class="mt-2 text-sm">
            How long have you been working at your current place of employment.
          </p>
        </div>
        <div class="mt-4 lg:mt-0 max-w-xl">
          <div class="flex flex-col input-wrap md:mt-0 mt-4">
            <label class="text-xs inline-block text-sec-text title"
              >Duration of employment</label
            >
            <div class="flex items-center w-full border rounded-lg bg-main-bg">
              <select
                class="bg-transparent w-full h-full text-title outline-none rounded-lg text-sm pl-4 minimal py-3"
                v-model="proBody.durationOfEmployment"
              >
                <option>Select</option>
                <option value="0 - 1 year">0 - 1 year</option>
                <option value="1 - 3 years">1 - 3 years</option>
                <option value="3 - 5 years">3 - 5 years</option>
                <option value="Above 5 years">above 5 years</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8 lg:mt-25 large-layout">
        <div class="lg:w-80">
          <span class="title">Alternative source of income</span>
          <div class="text-sec-text">
            <p class="mt-2 text-sm">
              Do you haveany other soure of income and how much is this income?
            </p>
            <p class="flex mt-4 text-info text-xs w-64">
              <span class="mr-2">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 6V10M10 14H10.01M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z" stroke="#2772EE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
                This would help us understand your income and increase your lending limits.
            </p>
          </div>
        </div>
        <div class="mt-4 lg:mt-0 max-w-xl">
          <div class="flex flex-col input-wrap md:mt-0 mt-4">
            <span class="text-xs inline-block text-sec-text title"
              >Alternative source of income</span
            >
            <div class="flex items-center">
              <div id="alt-income-yes">
                <input
                  class="mt-1"
                  type="radio"
                  name="alt-income"
                  autocomplete="off"
                  value="yes"
                  v-model="proBody.alternativeSourceOfIncome"
                />
                <label for="alt-income-yes" class="ml-2">Yes</label>
              </div>
              <div id="alt-income-no" class="ml-10">
                <input
                  class="mt-1"
                  type="radio"
                  name="alt-income"
                  autocomplete="off"
                  value="no"
                  v-model="proBody.alternativeSourceOfIncome"
                />
                <label for="alt-income-no" class="ml-2">No</label>
              </div>
            </div>
          </div>
          <div class="flex flex-col input-wrap mt-8">
            <label class="text-xs inline-block text-sec-text title"
              >Monthly alternative source of income</label
            >
            <div class="flex items-center w-full border rounded-lg bg-main-bg">
              <input
                class="bg-transparent w-full h-full text-title outline-none rounded-lg text-sm px-4 py-3"
                type="text"
                name="alternate-income"
                autocomplete="off"
                placeholder="Enter alternative income amount"
                v-model="proBody.monthlyAlternativeIncome"
                @keyup="altIncomeAmount()"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="large-layout my-10">
        <button
          @click.prevent="updateProSettings"
          class="bg-primary w-full rounded-lg h-12 hover:bg-bg-hover text-white font-medium text-sm tracking-wider max-w-xl col-start-2"
        >
          {{isRequest ? 'Updating...' : 'Save Changes'}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import { BASE_API } from "../../../constants";
// import axios from "axios";
import { mapState } from "vuex";
import { ApiInstance as api } from "../../utils";
import Settings from '../dashboard/Settings';

export default {
  name: "ProfessionalSettings",
  components: {
    Settings,
  },
  mounted() {
    this.initAutoComplete();
    api.get("/employment-details")
    .then(res => {
      if(res.data.statusCode === 200) {
        const {
          netMonthlyIncome,
          employerName,
          employerAddress,
          officialEmailAddress,
          durationOfEmployment,
          monthlyAlternativeIncome,
          alternativeSourceOfIncome
        } = res.data?.data;
        this.proBody = {
          employmentStatus: this.profile.user.employmentStatus,
          netMonthlyIncome: netMonthlyIncome.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          monthlyAlternativeIncome: monthlyAlternativeIncome.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          employerName,
          employerAddress,
          officialEmailAddress,
          durationOfEmployment,
          alternativeSourceOfIncome
        }
      }
    })
  },
  computed: {
    altIncome() {
      return this.proBody.alternativeSourceOfIncome;
    },
    ...mapState({
      profile: (state) => state.profile,
    }),
  },
  watch: {
    altIncome(event) {
      if (event === "yes") {
        this.proBody.alternativeSourceOfIncome = true;
      } else if (event === "no") {
        this.proBody.alternativeSourceOfIncome = false;
      }
    },
  },
  data() {
    return {
      isRequest: false,
      setBtnText: "Save Changes",
      spinner: "",
      proBody: {
        employmentStatus: "Select employment status",
        netMonthlyIncome: "",
        employerName: "",
        employerAddress: "",
        officialEmailAddress: "",
        durationOfEmployment: "Select",
        alternativeSourceOfIncome: "",
        monthlyAlternativeIncome: "",
      },
    };
  },
  methods: {
    income(event) {
      if (event?.key >= 37 && event?.key <= 40) return;
      this.proBody.netMonthlyIncome = this.proBody.netMonthlyIncome
        ?.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    altIncomeAmount(event) {
      if (event?.key >= 37 && event?.key <= 40) return;
      this.proBody.monthlyAlternativeIncome = this.proBody.monthlyAlternativeIncome
        ?.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    changeEmplymentStatus() {
      // alert(document.getElementById('employment-status').value)
      this.proBody.employmentStatus = document.getElementById('employment-status').value
    },
    initAutoComplete() {
      // eslint-disable-next-line no-undef
      let autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("employer-address"),
        {
          componentRestrictions: { country: ["NG"] },
        }
      );

      autocomplete.addListener("place_changed", () => {
        var place = autocomplete.getPlace();
        this.proBody.employerAddress = place.formatted_address;
      });
    },
    updateProSettings() {
      if (this.proBody.employmentStatus === "") {
        return this.$notie.alert({
          type: "error",
          text: "Please select employment status.",
          time: 3,
        });
      }
      if (this.proBody.netMonthlyIncome === "") {
        return this.$notie.alert({
          type: "error",
          text: "Please input net monthly income.",
          time: 3,
        });
      }
      if (this.proBody.employerName === "") {
        return this.$notie.alert({
          type: "error",
          text: "Please input name of employer.",
          time: 3,
        });
      }
      if (this.proBody.employerAddress === "") {
        return this.$notie.alert({
          type: "error",
          text: "Please select address of your place of work.",
          time: 3,
        });
      }
      if (this.proBody.officialEmailAddress === "") {
        return this.$notie.alert({
          type: "error",
          text: "Please input your work email address.",
          time: 3,
        });
      }
      if (this.proBody.durationOfEmployment === "") {
        return this.$notie.alert({
          type: "error",
          text: "Please select how long you have worked at your workplace.",
          time: 4,
        });
      }
      if (this.proBody.alternativeSourceOfIncome === "") {
        return this.$notie.alert({
          type: "error",
          text: "Please select if you have an alternative income source.",
          time: 4,
        });
      }
      if (this.proBody.alternativeSourceOfIncome === true) {
        if (this.proBody.monthlyAlternativeIncome === "") {
          return this.$notie.alert({
            type: "error",
            text: "Please input alternative monthly income amount.",
            time: 3,
          });
        } 
      }
      this.isRequest = true
      api
        .post("/employment-details/update/", {
          employmentStatus: this.proBody.employmentStatus,
          netMonthlyIncome: parseInt(this.proBody.netMonthlyIncome.split(',').join('')),
          employerName: this.proBody.employerName,
          employerAddress: this.proBody.employerAddress,
          officialEmailAddress: this.proBody.officialEmailAddress,
          durationOfEmployment: this.proBody.durationOfEmployment,
          alternativeSourceOfIncome: this.proBody.alternativeSourceOfIncome,
          monthlyAlternativeIncome: this.proBody.alternativeSourceOfIncome ? parseInt(this.proBody.monthlyAlternativeIncome.split(',').join('')) : 0
        })
        .then((res) => {
          this.isRequest = false
            this.$notie.alert({
              type: "success",
              text: "Professional settings updated successfully.",
              time: 3,
            });
          // if (res.status = 200) {
          // }
          return res
        })
        .catch((e) => {
          this.isRequest = false
          this.$notie.alert({
            type: "error",
            text: "Professional settings upload unsuccessful. Please try again",
            time: 3,
          });
          return e;
        });
    },
    
  },
};
</script>

<style lang="scss" scoped>
.title,
.img-link {
  font-family: SohneKraftig, sans-serif;
}
.large-layout {
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 440px 1fr;
  }
}
.minimal {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  background-position-y: calc(1.2rem), calc(1.2rem), 0.8em;
  background-position-x: calc(100% - 20px), calc(100% - 15px),
    calc(100% - 2.5em);
}
</style>